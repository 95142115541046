import bootstrap from 'bootstrap'
import $ from 'jquery'
// import easing from 'jquery.easing'
// import AOS from 'AOS'
// import device from 'current-device'
// import cookies from 'cookie_js'

// alert('yep');
// console.log("It's working!")

console.log('ding');


$.fn.remaining = function() {
        return this.each(function() {
            var _item = $(this);
            var time = _item.data('time');

            if (time < 0) {
                _item.html('Abgemeldet');
                return;
            }
            // console.log(time);
             _display(_convert(time));
            setInterval(
                function() {
                    _display(_convert(time));
                    time = time - 1;

                    
                    if (time < 0) {
                        location.reload();
                        time = 0;
                    }
                }, 1 * 1000);

            function _convert(_time) {
                 var hours = Math.floor(_time / 3600);
                    _time = _time - hours * 3600;

                    var minutes = Math.floor(_time / 60);
                    var seconds = _time - minutes * 60;

                    var finalTime = str_pad_left(hours, '0', 2) + ':' + str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
                    return finalTime;
            }
            function _display(_time) {
                 _item.children('span.value').html(_time)
            }
            function str_pad_left(string, pad, length) {
                return (new Array(length + 1).join(pad) + string).slice(-length);
            }
        });
    };



$(document).ready(function() {
	$('#remaining').remaining();

    var messages = {};
     messages.cookieMessage = 'Wir setzen nur technisch notwendige Cookies ein. Mit der weiteren Nutzung unseres Angebots erklären Sie sich mit der Verwendung von Cookies einverstanden.';
    messages.cookieButton = 'Einverstanden';
    messages.cookiePrivacy = 'Unsere Datenschutzerklärung';
    messages.link = '/datenschutz/';

    window.cookieconsent.initialise({
        cookie: {
            'domain': 'info.emilfrey-fdl.de',
        },
        type: 'info',
        position: 'bottom-right',
        theme: "edgeless",
        palette: {
            popup: { background: "#e5e5e5" },
            button: { background: "#2d52a5" },
        },
        revokable: false,
        content: {
            message: messages.cookieMessage,
            dismiss: messages.cookieButton,
            allow: 'Allow cookies',
            link: messages.cookiePrivacy,
            href: messages.url,
            close: '&#x274c;',
        },
        law: {
            regionalLaw: false,
        },
        location: false,
    });

	$('figure.replace-inline img').each(function() {
        _replaceSvg(this);
    });
});






/*
     * Replace all SVG images with inline SVG
     */
function _replaceSvg(_image) {
    var $img = $(_image);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    $.get(imgURL, function(data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find('svg');

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Replace image with new SVG
        $img.replaceWith($svg);
        $img.show();
    }, 'xml');
}




